<template>
    <div class="card card--bordered-slim card--widget">
        <b-dropdown id="dropdown-widget" right class="dropdown--widget">
            <b-dropdown-item @click="hideWidget">Close</b-dropdown-item>
            <b-dropdown-item @click="remindLater">Remind me later</b-dropdown-item>
        </b-dropdown>
            
        <div class="card--widget__inner">
            <header class="card--widget__header">
                <h4 class="heading4">Get started with SwitchPitch</h4>
                <a class="link" @click="openModal">{{watchedLessonsCount}} / {{allLessonsCount}} completed</a>
            </header>
            
            <!--<a @click="remindLater">Remind me later</a>-->
            <!--<a @click="hideWidget">Close</a>-->
            
            <b-progress :value="watchedLessonsCount" :max="allLessonsCount" class="widget-progress-bar"></b-progress>
    
            <div v-if="nextLessonNumber" class="card--widget__info" :class="lessonsIconsClasses[nextLessonNumber]">
                <div class="card--widget__info__inner">
                    <h6 class="heading6">{{ lessonsTitles[nextLessonNumber] }}</h6>
                    <p>{{ lessonsHints[nextLessonNumber] }}</p>
                </div>
    
                <a @click="() => {startLesson()}" class="button-widget">Start Lesson</a>
            </div>
    
            <div v-else class="card--widget__info card--widget__info--final">
                <div class="card--widget__info__inner">
                    <h6 class="heading6">You know everything you need to use SwitchPitch!</h6>
                    <p>
                        Click the help button to replay these lessons anytime.
                    </p>
                </div>
                <a @click="hideWidget" class="button-widget button-widget--active">Close</a>
            </div>
        </div>

        <b-modal id="lessons-modal" title="Get Started With SwitchPitch" modal-class="lessons-modal modal-w-md" hide-footer>
            <p>Complete the lessons below to get acquainted with the features most important to you.</p>

            <b-progress :value="watchedLessonsCount" :max="allLessonsCount" class="widget-progress-bar"></b-progress>

            <div class="widget-counter">{{watchedLessonsCount}} / {{allLessonsCount}} completed</div>
            
            <ul class="list-lessons">
                <li v-for="(watched, lessonNumber) in $store.state.user.lessons" :key="lessonNumber" class="list-lessons__search"
                    :class="lessonsIconsClasses[lessonNumber]">
                    <div class="list-lessons__inner">
                        <a @click="() => {startLesson(lessonNumber)}"><h6 class="heading6">{{ lessonsTitles[lessonNumber] }}</h6></a>
                        <p>{{ lessonsHints[lessonNumber] }}</p>
                    </div>
                    
                    <span v-if="watched" class="list-lessons__watched"></span>
                    <span v-else @click="() => {startLesson(lessonNumber)}" class="list-lessons__start"></span>
                </li>
            </ul>
        </b-modal>
    </div>
</template>

<script>
import {lessonsTitles, lessonsHints, lessonsCodes, lessonsIconsClasses} from "@/constants/lessons";
import userService from "@/services/user.service";

export default {
    computed: {
        lessonsTitles() {
            return lessonsTitles;
        },

        lessonsHints() {
            return lessonsHints;
        },

        lessonsIconsClasses() {
            return lessonsIconsClasses;
        },
    },
    data() {
        return {
            allLessonsCount: 0,
            watchedLessonsCount: 0,
            nextLessonNumber: null,
        }
    },

    mounted() {
        this.calculateNumbers();

        this.$store.watch(state => state.user.lessons, () => {
            this.calculateNumbers();
        })
    },

    methods: {
        calculateNumbers() {
            this.allLessonsCount = 0;
            this.watchedLessonsCount = 0;
            this.nextLessonNumber = null;

            for (let i in this.$store.state.user.lessons) {
                ++this.allLessonsCount;

                if (this.$store.state.user.lessons[i]) {
                    ++this.watchedLessonsCount
                } else if (!this.nextLessonNumber) {
                    this.nextLessonNumber = i;
                }
            }
        },

        openModal() {
            this.$bvModal.show('lessons-modal')
        },

        remindLater() {
            userService.remindLaterLessons().then(() => {
                this.$store.commit('setNeedUpdateUserData', true);

                this.$nextTick(() => {
                    this.$store.commit('setNeedUpdateUserData', false);
                })
            })
        },

        hideWidget() {
            userService.hideLessonsWidget().then(() => {
                this.$store.commit('setNeedUpdateUserData', true);

                this.$nextTick(() => {
                    this.$store.commit('setNeedUpdateUserData', false);
                })
            })
        },

        startLesson(lessonNumber = this.nextLessonNumber) {
            this.$bvModal.hide('lessons-modal')
            window.pendo.showGuideById(lessonsCodes[lessonNumber]);
        },
    }
}
</script>

<style scoped>

</style>
