import { render, staticRenderFns } from "./LessonsHomeWidget.vue?vue&type=template&id=a300aa8a&scoped=true&"
import script from "./LessonsHomeWidget.vue?vue&type=script&lang=js&"
export * from "./LessonsHomeWidget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a300aa8a",
  null
  
)

export default component.exports